.loading-indicator #loader-inner {
	z-index: 100000000;
	background-color: #000;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 90px;
	height: 90px;
	border-radius: 150px;
	-webkit-border-radius: 150px;
	-moz-border-radius: 150px;
	-ms-border-radius: 150px;
	-o-border-radius: 150px;
	display: flex;
	align-items: center;
	justify-content: center;

	img {
		height: 50px;
	}
}

.loading-indicator:before,
.loading-dna-indicator:before {
	content: "";
	background: rgba(0, 0, 0, 0.5);
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 10000000;
}

.loading-indicator:after,
.loading-dna-indicator:after {
	content: "";
	position: absolute;
	width: 75px;
	top: 0;
	z-index: 100000000;
	background-size: contain;
	display: block;
	height: 75px;
	background-position: center;
	left: 0;
	right: 0;
	margin: auto;
	bottom: 0;
	background-repeat: no-repeat;
	background-image: url("../assets/img/loader.svg");
}

.loading-indicator-footer:before {
	content: "";
	background: #f7f7f780;
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 10000000;
}

.loading-indicator-footer:after {
	content: "";
	position: absolute;
	width: 50px;
	top: 0;
	z-index: 100000000;
	background-size: contain;
	display: block;
	height: 50px;
	background-position: center;
	left: 0;
	right: 0;
	margin: auto;
	bottom: 0;
	background-repeat: no-repeat;
	background-image: url("../assets/img/loader.svg");
}
@primary-color: #b69865;