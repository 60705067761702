@import "../styles/variable.less";

.botoomStickyBar {
  box-shadow: 0px -2px 16px 0px rgba(0, 0, 0, 0.09);
  background: #fff;
  position: absolute;
  bottom: 0;
  padding: 10px 15px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  left: 0;
  z-index: 1;

  .diamond-list-btn {
    align-items: center;
  }
}

.botoomStickyBar .bottomStickyButton {
  border: 1px solid @themeColor;
  border-radius: 5px;
  margin: 0 3px;
  background-color: transparent;
  padding: 5px 5px;
  border-radius: 5px;
  line-height: 18px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  background-color: @themeColor;

  &.disable {
    opacity: 0.7;
    cursor: not-allowed;
    pointer-events: none;

    &:hover {
      border: 1px solid @themeColor;
      background-color: @themeColor;
      color: #fff;

      span {
        color: #fff;
      }
    }
  }

  &.buttonHlightlight {
    position: relative;

    &:before {
      content: "";
      // background-color: #f0d8947d;
      position: absolute;
      top: -2px;
      left: -2px;
      background-size: 400%;
      z-index: -1;
      filter: blur(5px);
      width: calc(~"100% + 4px");
      height: calc(~"100% + 4px");
      animation: glowing 1500ms linear infinite;
      opacity: 1;
      transition: opacity 0.3s ease-in-out;
      border-radius: 10px;
    }
  }
}

.botoomStickyBar .bottomStickyButton:focus {
  outline: none;
}

.botoomStickyBar .bottomStickyButton span {
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  transition: all 0.5s ease-in-out;
}

.botoomStickyBar .bottomStickyButton:hover {
  background-color: #fff;
}

.botoomStickyBar .bottomStickyButton:hover span {
  color: @themeColor;
}

.botoomStickyBar .details {
  text-align: right;
  span {
    text-decoration: underline;
    color: rgb(0, 140, 186);
  font-weight: 500;

    
  }
}

.botoomStickyBar .fileTitle {
  color: #212431;
  border-radius: 5px;
  background: #fff;
  position: relative;
  margin-left: 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}

.mailSecDIV {
  margin-left: auto;
  button {
    font-weight: 600;
  }
}

.searchInnerFilterMain {
  display: flex;
  align-items: center;
  padding: 0 10px;

  .searchInnerFilterItem {
    display: flex;
    align-items: center;
    border-radius: 20px;
    margin-right: 10px;
    border: 1px solid #ddd;
    background-color: #fafafa;
    cursor: pointer;

    .searchInnerFilterTitle {
      color: #000;
      font-size: 14px;
      font-weight: 600;
      padding: 4px 0 4px 10px;
    }

    .searchMainFilterIcon {
      margin-left: 5px;

      img {
        width: 12px;
        height: 12px;
        display: block;
      }

      &.filterClose {
        padding: 5px 10px 5px 8px;
        border-left: 1px solid @themeDarkBoreder;

        img {
          width: 10px;
          height: 10px;
        }
      }
    }

    &.active {
      background-color: #eee5dc;
      border: 1px solid @themeColor;
    }
  }

  .firDIV {
    button {
      padding: 2px;
      font-weight: 600;

      img {
        margin-left: 2px;
      }
    }
  }

  .secDIV {
    display: flex;
    position: absolute;
    width: 100px;
    right: 12px; //88px;

    button {
      font-weight: 600;
    }
  }
  
  .fileTitle {
    color: #212431;
    padding: 5px 10px;
    border-radius: 5px;
    background: #fff;
    position: relative;
    margin-right: 20px;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
  }
}
@primary-color: #b69865;